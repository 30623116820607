<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16' 
    :style='minHeightStyle'>
    <div
      v-if='showSideNav'
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0 sticky top-16 z-10'
      :style='`${sidebarBgStyle}`'>
      <h2 v-if='sidebarPageTitle'
        class='text-2xl uppercase'>
        {{ sidebarPageTitle }}
      </h2>
      <ul class='text-xs sidebar-navigation-links flex flex-row lg:flex-col sticky top-24 overflow-x-auto'>
        <li v-for='(sidebarLink, index) in sidebarLinks'
          :key='`sidebar-link-${sidebarLink.title}-${index}`'
          class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link v-if='sidebarLink.actionType==="open_internal_route"'
            :to='{ name: sidebarLink.link }'
            class='whitespace-nowrap text-sm lg:text-lg py-2 px-2 lg:pl-2 lg:pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            :class='sidebarLink.activeCssClass'>
            {{ sidebarLink.title }}
          </router-link>
          <a v-else-if='sidebarLink.actionType==="open_external_link"'
            target='_blank' 
            :href='sidebarLink.link'
            class='whitespace-nowrap text-sm lg:text-lg py-2 px-2 lg:pl-2 lg:pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            :class='sidebarLink.activeCssClass'>
            {{ sidebarLink.title }}
          </a>
        </li>
      </ul>
    </div>
    <div class='flex-grow relative px-4 lg:px-0'>
      <div v-html='mainBodyContent'></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarPage',
  components: {
  },
  props: {
    mainBodyContent: {
      type: String,
      default: '',
    },
    sidebarPageTitle: {
      type: String,
      default: '',
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    customBackgroundStyle: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      showSideNav: true,
    }
  },
  watch: {
    '$route.query.on_mobile_app': {
      handler: function (newVal) { 
        if (!newVal) {// show if the query param on_mobile_app does not exist
          this.showSideNav = true
        } else if (newVal !== 'true') {//  or show if string is not 'true'
          this.showSideNav = true
        } else {
          this.showSideNav = false
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
  },
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>


